<template>
    <section>
        <h3 class="text-2xl font-bold mb-5">Suche</h3>
        <div class="flex">
            <div class="form-control flex-1 mr-20">
                <input type="text" autocomplete="off" v-model="filter">
                <i class="fas fa-search" style="top: 1.3rem"></i>
            </div>
            <div class="form-control">
                <input type="date" v-model="datetime" class="date"/>
            </div>
        </div>
        <!-- <DatePicker /> -->
        <Tickets :filter="filter" :datetime="datetime" :solved="true" />
    </section>
</template>

<style scoped lang="scss">
    .date {
        width: 100%;
        max-width: 30rem;
    }
</style>

<script>
    import Tickets from '@/components/Tickets.vue';

    export default {
        data() {
            return {
                filter: '',
                datetime: new Date().toISOString().split('T')[0]
            }
        },
        components: {
            Tickets
        },
    }
</script>